import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    createOrder,
	getOrderBySlug,
    updateOrder,
    updateOrderInfo,
    addOrderItem,
    updateOrderItem,
    setOrderInfoSelected
} from '../../actions/order';
import { mainOrderStatuses, orderStatuses, orderItemsStatuses } from '../../constants/order';
import * as PROFILE from '../../constants/auth';
import * as PROJECT from '../../constants/project';
import { API_URL } from '../../constants/api';
import { phoneSchema } from '../../validations/account';
import {
    companySchema,
    firstnameSchema,
    lastnameSchema,
} from '../../validations/profile';
import {
    eventTypeSchema,
    guestsSchema,
    receiveDateSchema,
    returnDateSchema,
    endDatetimeSchema,
    locationSchema,
} from '../../validations/project';
import Breadcrumbs from '../Common/Breadcrumbs';
import noPhoto from '../../assets/images/profile-no-photo.png';
import ProfileSocials from '../Auth/ProfileSocials';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from  "react-datepicker";
import { uk } from 'date-fns/locale/uk';
import checkboxActive from '../../assets/icons/checkbox-active.svg';
import checkboxInactive from '../../assets/icons/checkbox-inactive.svg';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import noImage from '../../assets/images/product-no-image.png';
import plus from '../../assets/icons/plus-white.svg';
import AddOrderItem from '../Popup/AddOrderItem';

const Order = () => {
    registerLocale('uk', uk);

    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const slug = pathArray.length === 3 ? pathArray[2] : null;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const order = useSelector((state) => state.order.order);
    const orderLoading = useSelector((state) => state.order.orderLoading);

    const [isOwn, setIsOwn] = useState(false);
    const [mode, setMode] = useState(null);

    const initialProfileData = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        viber: '',
        whatsapp: '',
        telegram: '',
        instagram: '',
    }

    const [profileData, setProfileData] = useState(initialProfileData);

    const initialProjectData = {
        // client: '',
        eventType: '',
        guests: '',
        receiveDate: null,
        returnDate: null,
        endDatetime: null,
        location: '',
        status: 'new'
    }

    const [projectData, setProjectData] = useState(initialProjectData);

    const initialOrderInfoData = {
        company: '',
        firstname: '',
        lastname: '',
        phone: '',
        eventType: '',
        guests: '',
        receiveDate: null,
        returnDate: null,
        endDatetime: null,
        location: '',
        status: 'confirmed'
    }

    const [orderInfoData, setOrderInfoData] = useState(initialOrderInfoData);

    useEffect(() => {
        const fetchData = async () => {
	    	if (isAuthenticated) {
	    		if (slug) {
                    if (slug == 'create') {
                        // setProfileData(initialProfileData);
                        // setProjectData({ ...initialProjectData, status: orderStatuses['confirmed'] });
                        setOrderInfoData(initialOrderInfoData);
                        setMode('create');
                        setIsOwn(true);
                    } else {
                		const resultOrder = await dispatch(getOrderBySlug(slug));
                		if (resultOrder) {
                			if (resultOrder.ownerId !== authUser.id) {
                    			navigate('/404');
                			}
                		} else {
                    		navigate('/404');
                		}
                    }
	    		} else {
		    		navigate('/');
		    	}
	    	} else if (!userLoading) {
	    		navigate('/');
	    	}
        }

        fetchData();
    }, [slug, userLoading, isAuthenticated, authUser]);

    const profileParams = {
        firstname: { label: PROFILE.FIRSTNAME, value: profileData.firstname },
        lastname: { label: PROFILE.LASTNAME, value: profileData.lastname },
        email: { label: PROFILE.EMAIL, value: profileData.email },
        phone: { label: PROFILE.PHONE, value: profileData.phone }
    }

    const [customerAvatar, setCustomerAvatar] = useState(noPhoto);

    const projectParams = {
        // client: { label: PROJECT.CLIENT, value: projectData.client },
        eventType: { label: PROJECT.EVENT_TYPE, value: projectData.eventType },
        guests: { label: PROJECT.GUESTS, value: projectData.guests },
        receiveDate: { label: PROJECT.RECEIVE_DATE, value: projectData.receiveDate },
        returnDate: { label: PROJECT.RETURN_DATE, value: projectData.returnDate },
        endDatetime: { label: PROJECT.END_DATETIME, value: projectData.endDatetime },
        location: { label: PROJECT.LOCATION, value: projectData.location },
        status: { label: PROJECT.STATUS, value: projectData.status }
    }

    const infoProfileParams = {
        company: {
            field: { label: PROFILE.COMPANY, name: 'company', value: orderInfoData.company, ref: useRef(null) },
            validationSchema: companySchema
        },
        firstname: {
            field: { label: PROFILE.FIRSTNAME, name: 'firstname', value: orderInfoData.firstname, ref: useRef(null) },
            validationSchema: firstnameSchema
        },
        lastname: {
            field: { label: PROFILE.LASTNAME, name: 'lastname', value: orderInfoData.lastname, ref: useRef(null) },
            validationSchema: lastnameSchema
        },
        phone: {
            field: { label: PROFILE.PHONE, name: 'phone', value: orderInfoData.phone, ref: useRef(null) },
            validationSchema: phoneSchema
        }
    }

    const infoProjectParams = {
        eventType: {
            field: { label: PROJECT.EVENT_TYPE, name: 'eventType', value: orderInfoData.eventType, ref: useRef(null) },
            validationSchema: eventTypeSchema
        },
        guests: {
            field: { label: PROJECT.GUESTS, name: 'guests', value: orderInfoData.guests, ref: useRef(null) },
            validationSchema: guestsSchema
        },
        receiveDate: {
            field: { label: PROJECT.RECEIVE_DATE, name: 'receiveDate', value: orderInfoData.receiveDate, ref: useRef(null) },
            validationSchema: receiveDateSchema
        },
        returnDate: {
            field: { label: PROJECT.RETURN_DATE, name: 'returnDate', value: orderInfoData.returnDate, ref: useRef(null) },
            validationSchema: returnDateSchema
        },
        endDatetime: {
            field: { label: PROJECT.END_DATETIME, name: 'endDatetime', value: orderInfoData.endDatetime, ref: useRef(null) },
            validationSchema: endDatetimeSchema
        },
        location: {
            field: { label: PROJECT.LOCATION, name: 'location', value: orderInfoData.location, ref: useRef(null) },
            validationSchema: locationSchema
        }
    }

    const [items, setItems] = useState([]);

    const discountRef = useRef(null);
    const [discount, setDiscount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        if (order && slug !== 'create') {
            if (order.project && order.customer) {
                if (order.customer.avatars.length != 0) {
                	setCustomerAvatar(order.customer.avatars[0].path);
                } else {
                	setCustomerAvatar(noPhoto);
            	}

                setProfileData({                
                    firstname: order.customer.profile.firstname || '',
                    lastname: order.customer.profile.lastname || '',
                    email: order.customer.email || '',
                    phone: order.customer.phone || '',
                    viber: order.customer.profile.viber || '',
                    whatsapp: order.customer.profile.whatsapp || '',
                    telegram: order.customer.profile.telegram || '',
                    instagram: order.customer.profile.instagram || '',
                });

                setProjectData({     
                    // client: order.project.client || '',
                    eventType: order.project.eventType || '',
                    guests: order.project.guests || '',
                    receiveDate: new Intl.DateTimeFormat('uk-UA', {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        timeZone: 'Europe/Kyiv',
                    }).format(new Date(order.receiveDate)),
                    returnDate: new Intl.DateTimeFormat('uk-UA', {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        timeZone: 'Europe/Kyiv',
                    }).format(new Date(order.returnDate)),
                    endDatetime: new Intl.DateTimeFormat('uk-UA', {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: 'Europe/Kyiv',
                    }).format(new Date(order.project.endDatetime)),
                    location: order.project.location || '',
                    status: orderStatuses[order.status] || '',
                });

                setMode('show');
                setIsOwn(false);
            } else if (order.info) {
                setOrderInfoData({
                    company: order.info.company || '',
                    firstname: order.info.firstname || '',
                    lastname: order.info.lastname || '',
                    phone: order.info.phone || '',
                    eventType: order.info.eventType || '',
                    guests: order.info.guests || '',
                    receiveDate: new Date(order.receiveDate),
                    returnDate: new Date(order.returnDate),
                    endDatetime: new Date(order.info.endDatetime),
                    location: order.info.location || '',
                    status: orderStatuses[order.status] || '',
                });

                setMode('edit');
                setIsOwn(true);
            }

            setItems(order.items);
            setDiscount(order.discount)
            setTotalCost(order.totalCost)
        }
    }, [order, slug]);

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else if (path.includes('uploads')) return `${API_URL}/${path}`;
        else return path;
    }

    const getOrderStatusIndex = (status) => {
        return Object.keys(mainOrderStatuses).findIndex(item => item === status);
    }

    const setOrderStatus = async (e, status) => {
        const checked = e.target.checked;
        const statusIndex = getOrderStatusIndex(status);
        let newStatus = status;
        if (!checked) {
            const newStatusIndex = statusIndex - 1;
            newStatus = (newStatusIndex <= -1) ? 'pending' : Object.keys(mainOrderStatuses)[newStatusIndex];
        }
        await dispatch(updateOrder({ id: order.id, status: newStatus }));
    }

    const [validationErrors, setValidationErrors] = useState({
        company: null,
        firstname: null,
        lastname: null,
        phone: null,
        eventType: null,
        guests: null,
        receiveDate: null,
        returnDate: null,
        endDatetime: null,
        location: null
    });

    const [infoProfileValidationError, setInfoProfileValidationError] = useState(null);
    const [infoProjectValidationError, setInfoProjectValidationError] = useState(null);

    const setError = (name, message, type) => {
        setValidationErrors({
            ...validationErrors,
            [name]: message,
        });
        if (type === 'profile') setInfoProfileValidationError(message);
        if (type === 'project') setInfoProjectValidationError(message);
        return false;
    }

    const handleReceiveDateChange = (selectedDate) => {
        setOrderInfoData({
            ...orderInfoData,
            receiveDate: selectedDate,
        });
    }

    const handleReturnDateChange = (selectedDate) => {
        setOrderInfoData({
            ...orderInfoData,
            returnDate: selectedDate,
        });
    }

    const handleEndDatetimeChange = (selectedDate) => {
        setOrderInfoData({
            ...orderInfoData,
            endDatetime: selectedDate,
        });
    }

    const handleInputChange = (e, param) => {
        const { name, value } = e.target;

        setOrderInfoData({
            ...orderInfoData,
            [name]: value
        });

        setValidationErrors({
            ...validationErrors,
            [name]: null,
        });

        setInfoProfileValidationError(null);
        setInfoProjectValidationError(null);
    }

    const getOrderItemStatusIndex = (status) => {
        return Object.keys(orderItemsStatuses).findIndex(item => item === status);
    }

    const setOrderItemStatus = async (e, itemId, status) => {
        const checked = e.target.checked;
        const statusIndex = getOrderItemStatusIndex(status);
        let newStatus = status;
        if (!checked) {
            const newStatusIndex = statusIndex - 1;
            newStatus = (newStatusIndex <= -1) ? 'new' : Object.keys(orderItemsStatuses)[newStatusIndex];
        }
        await dispatch(updateOrderItem({ id: itemId, status: newStatus }));
        await dispatch(getOrderBySlug(slug));
    }

    const handleAmountChange = async (e, item) => {
        const newAmount = Number(e.target.value);
        if (!isNaN(newAmount) && newAmount >= 0) {
            const updatedItems = items.map((i) => i.id === item.id ? { ...i, amount: newAmount } : i);
            setItems(updatedItems);
            let newTotalCost = updatedItems.reduce((sum, item) => sum + (item.amount * item.price), 0);
            newTotalCost = Number((newTotalCost - newTotalCost * discount / 100).toFixed(0));
            setTotalCost(newTotalCost)
            await dispatch(updateOrderItem({ id: item.id, amount: newAmount }))
            await dispatch(updateOrder({ id: order.id, totalCost: newTotalCost }));
        }
    }

    const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);

    const handleAddOrderItemClick = () => {
        setAddItemPopupOpen(true);
    }

    const handleConfirmAddOrderItem = async (data) => {
        await dispatch(addOrderItem({ orderId: order.id, ...data }));
        // dialog.order.items.push(data);
        const newItems = [ ...items, data ]
        setItems(newItems);
        let newTotalCost = newItems.reduce((sum, item) => sum + (Number(item.amount) * Number(item.price)), 0);
        console.log(newTotalCost)
        newTotalCost = Number((newTotalCost - newTotalCost * discount / 100).toFixed(0));
        setTotalCost(newTotalCost)
        setAddItemPopupOpen(false);
    }

    const handleCancelAddOrderItem = () => {
        setAddItemPopupOpen(false);
    }

    const goToCatalog = async (url = '/catalog') => {
        await dispatch(setOrderInfoSelected(order.id));
        navigate(url);
    }

    const handleDiscountChange = async (e) => {
        const newDiscount = Number(e.target.value);
        if (!isNaN(newDiscount) && newDiscount >= 0 && newDiscount <= 100) {
            let newTotalCost = order.items.reduce((sum, item) => sum + (item.amount * item.price), 0);
            newTotalCost = Number((newTotalCost - newTotalCost * newDiscount / 100).toFixed(0));
            setDiscount(newDiscount);
            setTotalCost(newTotalCost)
            await dispatch(updateOrder({ id: order.id, discount: newDiscount, totalCost: newTotalCost }));
        }
    }

    const validateForm = async () => {
        let validatingParam = null;
        let validatingType = null;
        try {
            for (const paramsData of [
                { type: 'profile', params: infoProfileParams },
                { type: 'project', params: infoProjectParams },
            ]) {
                validatingType = paramsData.type;
                for (const param in paramsData.params) {
                    validatingParam = paramsData.params[param];
                    await paramsData.params[param].validationSchema.validate({
                        [param]: orderInfoData[param] 
                    }, { abortEarly: false });
                }
            }
            return true;
        } catch (errors) {
            // console.log(errors)
            let error = errors.inner[1] || errors.inner[0];
            return setError(validatingParam, error.message, validatingType);
        }
    }

    const saveOrder = async () => {
        if (isOwn) {
            const formValid = await validateForm();
            if (formValid === true) {
                if (mode == 'create') {
                    const createdOrder = await dispatch(createOrder(orderInfoData));
                    navigate(`/order/${createdOrder.slug}`);
                } else if (mode == 'edit') {
                    await dispatch(updateOrderInfo({ id: order.id, ...orderInfoData }));
                }
            }
        }
    }

	return (
        <div className="order container">
            <Breadcrumbs link="/" />
            <div className="order-blocks d-flex flex-column">
            	<div className="order-customer d-flex">
            		<div className="order-customer-avatar">
            			<img className="w-100" src={getImageUrl(customerAvatar)} alt="" />
            		</div>
            		<div className="order-customer-right h-100 d-flex flex-column justify-content-between">
            			{!isOwn && order && order.customer && order.dialog && <div className="order-customer-buttons d-flex flex-column">
		                    <Link to={`/profile/${order.customer.slug}`} className="btn btn-medium btn-black">
		                        <span>Перейти до профілю замовника</span>
		                    </Link>
		                    <Link to={`/dialog/${order.dialog.id}`} className="btn btn-medium btn-black">
		                        <span>Перейти до діалогу</span>
		                    </Link>
            			</div>}
            			<div className="order-customer-info d-flex flex-column justify-content-end relative">
            				{!isOwn && order && order.customer && <><div className="order-customer-info-profile d-flex flex-column">
                            {Object.keys(profileParams).map((param, index) => {
                                const { label, value } = profileParams[param];
                                return (
	            					<div className="order-customer-info-profile-item w-100 d-flex" key={index}>
	            						<div className="order-customer-info-profile-item-label">{label}</div>
	            						<div className="order-customer-info-profile-item-value">{value}</div>
	            					</div>
                                )
                            })}
            				</div>
                        	<ProfileSocials profileData={profileData} /></>}
                            {infoProfileValidationError && <div className="order-error-message auth-message w-100 absolute text-center text-red">{infoProfileValidationError}</div>}
                            {isOwn && order && order.info && <div className="order-customer-info-own d-flex flex-column">
                                {Object.keys(infoProfileParams).map((param, index) => {
                                    const { label, name, value, ref } = infoProfileParams[param].field;
                                    return (
                                        <div className="order-customer-info-own-item w-100 d-flex" key={index}>
                                            <div className="order-customer-info-own-item-label">{label}</div>
                                            <div className="order-customer-info-own-item-input w-100 flex-1">
                                                {name == 'phone' 
                                                    ? <InputMask
                                                        className="w-100 h-100"
                                                        type="text"
                                                        name={name}
                                                        mask={PROFILE.PHONE_MASK}
                                                        value={value}
                                                        ref={ref}
                                                        placeholder={label}
                                                        onChange={(e) => handleInputChange(e, param)}
                                                    />
                                                    : <input className="w-100 h-100 text-black" type="text" name={name} value={value} ref={ref} placeholder={label} onChange={(e) => handleInputChange(e, param)} />
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}
            			</div>
            		</div>
            	</div>
            	<div className="order-details d-flex flex-column align-items-center">
            		<div className="order-details-title">Деталі замовлення</div>
            		{order && <div className="order-details-form d-flex flex-column">
            			<div className="order-details-form-row w-100 d-flex">
            				<div className="d-flex align-items-center">Приблизний час отримання оренди</div>
            				<div className="d-flex align-items-center">Замовлення готове до видачі</div>
            				<div className="d-flex align-items-center">Замовлення видано</div>
            				<div className="d-flex align-items-center">Замовлення отримано</div>
            				<div className="d-flex align-items-center">Замовлення вважається завершеним</div>            				
            			</div>
            			<div className="order-details-form-row w-100 d-flex">
            				<div className="d-flex justify-content-center align-items-center">{
								new Intl.DateTimeFormat('uk-UA', {
				                    day: "numeric",
				                    month: "numeric",
				                    year: "numeric",
				                    timeZone: 'Europe/Kyiv',
				                }).format(new Date(order.receiveDate))
							}</div>
							{Object.keys(mainOrderStatuses).map((status, index) => {
                                const checked = getOrderStatusIndex(order.status) >= getOrderStatusIndex(status)
								return (
		            				<div
                                        className="d-flex justify-content-center align-items-center"
                                        key={index}                                        
                                    >
                                        <label htmlFor={`order-${status}`}>
    		            					<input
                                                id={`order-${status}`}
                                                style={{ display: 'none' }}
    		            						type="checkbox"
    		            						checked={checked}
                                                onChange={(e) => setOrderStatus(e, status)}
    		            					/>
                                            <img src={checked ? checkboxActive : checkboxInactive} alt="" />
                                        </label>
		            				</div>
								)
							})}
            			</div>
            		</div>}
                    {isOwn && order && order.info && <div className="project-data-fields w-100 d-flex flex-column relative">
                        {infoProjectValidationError && <div className="order-error-message auth-message w-100 absolute text-center text-red">{infoProjectValidationError}</div>}
                        {Object.keys(infoProjectParams).map((param, index) => {
                            const { field } = infoProjectParams[param]
                            const { label, name, value, ref } = field;
                            return (
                                <div className={`project-data-field d-flex`} key={index}>
                                    <div className="project-data-field-label d-flex justify-content-center align-items-center text-black">{label}</div>
                                    <div className="project-data-field-input d-flex justify-content-between align-items-center">
                                        {name == 'receiveDate' && 
                                            <DatePicker
                                                className="w-100"
                                                placeholderText={label}
                                                locale="uk"
                                                dateFormat="dd.MM.yyyy"
                                                selected={orderInfoData.receiveDate}
                                                onChange={handleReceiveDateChange}
                                                minDate={new Date()}
                                            />
                                        }
                                        {name == 'returnDate' && 
                                            <DatePicker
                                                className="w-100"
                                                placeholderText={label}
                                                locale="uk"
                                                dateFormat="dd.MM.yyyy"
                                                selected={orderInfoData.returnDate}
                                                onChange={handleReturnDateChange}
                                                minDate={new Date()}
                                            />
                                        }
                                        {name == 'endDatetime' && 
                                            <DatePicker
                                                className="w-100"
                                                placeholderText={label}
                                                locale="uk"
                                                dateFormat="dd.MM.yyyy HH:mm"
                                                selected={orderInfoData.endDatetime}
                                                onChange={handleEndDatetimeChange}
                                                minDate={new Date()}
                                                showTimeSelect
                                            />
                                        }
                                        {name != 'receiveDate' && name != 'returnDate' && name != 'endDatetime' && 
                                            <input className="w-100 h-100 text-black" type="text" name={name} value={value} ref={ref} placeholder={label} onChange={(e) => handleInputChange(e, param)} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                    {!isOwn && order && order.project && <div className="project-data-fields w-100 d-flex flex-column">
                        {Object.keys(projectParams).map((param, index) => {
                            const { label, value } = projectParams[param];
                            return (
                                <div className={`project-data-field d-flex`} key={index}>
                                    <div className="project-data-field-label d-flex justify-content-center align-items-center text-black">{label}</div>
                                    <div className="project-data-field-input d-flex justify-content-between align-items-center">
                                        <span className="w-100">{value}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
            	</div>
            	{order && <div className="order-items d-flex flex-column align-items-center">
                    <div className="order-items-title">Опис замовлення</div>
                    {items.length !== 0 && <><Table className="order-items-table w-100">
                        <TableHead>
                            <TableRow className="order-items-table-row">
                                <TableCell className="order-items-table-cell order-items-table-cell-num">№</TableCell>
                                <TableCell className="order-items-table-cell order-items-table-cell-image"></TableCell>
                                <TableCell className="order-items-table-cell">Назва</TableCell>
                                <TableCell className="order-items-table-cell order-items-table-cell-amount">Кількість, шт.</TableCell>
                                <TableCell className="order-items-table-cell">Ціна оренди, грн.</TableCell>
                                <TableCell className="order-items-table-cell">Нестача/Знищення, шт.</TableCell>
                                <TableCell className="order-items-table-cell">Ціна відшкодування, грн.</TableCell>
                                <TableCell className="order-items-table-cell">Потрібно для відновлення, шт.</TableCell>
                                <TableCell className="order-items-table-cell">Ціна для відновлення, грн.</TableCell>
                                <TableCell className="order-items-table-cell">Сума, грн.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => {
                                const { id, title, amount, lostAmount, restoreAmount, product } = item;
                                const productImage = product && product.images.length !== 0 ? product.images[0].path : noImage;
                                let rentalPrice, refundPrice = 0, restorePrice = 0;
                                if (product) {
                                    rentalPrice = product.features.rentalPrice;
                                    refundPrice = product.features.refundPrice;
                                    restorePrice = product.features.restorePrice;
                                } else {
                                    rentalPrice = item.price;
                                }
                                return (<React.Fragment key={index}>
                                    <TableRow className="order-items-table-row">
                                        <TableCell className="order-items-table-cell order-items-table-cell-num">{index + 1}</TableCell>
                                        <TableCell className="order-items-table-cell order-items-table-cell-image" rowSpan="2">
                                            {product && <Link to={`/product/${product.slug}`} className="w-100">
                                                <img src={getImageUrl(productImage)} alt="" />
                                            </Link>}
                                        </TableCell>
                                        <TableCell className="order-items-table-cell order-items-table-cell-title">
                                            {product 
                                                ?   <Link to={`/product/${product.slug}`}>{title}</Link>
                                                :   <span>{title}</span>
                                            }
                                        </TableCell>
                                        <TableCell className="order-items-table-cell order-items-table-cell-amount">
                                            <input className="w-100 h-100 order-discount text-black" type="text" value={amount} onChange={(e) => handleAmountChange(e, item)} />
                                        </TableCell>
                                        <TableCell className="order-items-table-cell">{rentalPrice}</TableCell>
                                        <TableCell className="order-items-table-cell">{lostAmount}</TableCell>
                                        <TableCell className="order-items-table-cell">{refundPrice}</TableCell>
                                        <TableCell className="order-items-table-cell">{restoreAmount}</TableCell>
                                        <TableCell className="order-items-table-cell">{restorePrice}</TableCell>
                                        <TableCell className="order-items-table-cell">{
                                            amount * rentalPrice + lostAmount * refundPrice + restoreAmount * restorePrice
                                        }</TableCell>
                                    </TableRow>
                                    <TableRow className="order-items-table-row">
                                        <TableCell className="order-items-table-cell"></TableCell>
                                        <TableCell className="order-items-table-cell"></TableCell>
                                        <TableCell className="order-items-table-cell"></TableCell>
                                        {Object.keys(orderItemsStatuses).map((status, statusIndex) => {
                                            if (status != 'new') {
                                                const checked = getOrderItemStatusIndex(item.status) >= getOrderItemStatusIndex(status)
                                                return (
                                                    <React.Fragment key={statusIndex}>
                                                        <TableCell className="order-items-table-cell">{
                                                            orderItemsStatuses[status]
                                                        }</TableCell>
                                                        <TableCell className="order-items-table-cell">
                                                            <label htmlFor={`order-item-${item.id}-${status}`}>
                                                                <input
                                                                    id={`order-item-${item.id}-${status}`}
                                                                    style={{ display: 'none' }}
                                                                    type="checkbox"
                                                                    checked={checked}
                                                                    onChange={(e) => setOrderItemStatus(e, id, status)}
                                                                />
                                                                <img src={checked ? checkboxActive : checkboxInactive} alt="" />
                                                            </label>
                                                        </TableCell>
                                                    </React.Fragment>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                </React.Fragment>)
                            })}
                            <TableRow className="order-items-table-row">
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell">Знижка, %</TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell">
                                    <input className="w-100 h-100 order-discount text-black" type="text" name="discount_percent" value={discount || 0} ref={discountRef} onChange={(e) => handleDiscountChange(e)} />
                                </TableCell>
                            </TableRow>
                            <TableRow className="order-items-table-row">
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell">Всього, грн</TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell"></TableCell>
                                <TableCell className="order-items-table-cell">{totalCost}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className="order-items-mobile">
                        {items.map((item, index) => {
                            const { id, title, amount, lostAmount, restoreAmount, product } = item;
                            const productImage = product && product.images.length !== 0 ? product.images[0].path : noImage;
                            let rentalPrice, refundPrice = 0, restorePrice = 0;
                            if (product) {
                                rentalPrice = product.features.rentalPrice;
                                refundPrice = product.features.refundPrice;
                                restorePrice = product.features.restorePrice;
                            } else {
                                rentalPrice = item.price;
                            }
                            return (
                                <div className="order-items-mobile-item d-flex flex-column" key={index}>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">№</div>
                                        <div className="d-flex justify-content-center align-items-center">{index + 1}</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Назва</div>
                                        <div className="order-items-mobile-item-name d-flex flex-column justify-content-between align-items-center">
                                            <div className="order-items-mobile-item-title">{title}</div>
                                            <div className="order-items-mobile-item-image w-100">
                                                <img src={getImageUrl(productImage)} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Кількість, шт.</div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <input className="w-100 h-100 order-discount text-black" type="text" value={amount} onChange={(e) => handleAmountChange(e, item)} />
                                        </div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Ціна оренди, грн.</div>
                                        <div className="d-flex justify-content-center align-items-center">{rentalPrice}</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Нестача/Знищення, шт.</div>
                                        <div className="d-flex justify-content-center align-items-center">{lostAmount}</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Ціна відшкодування, грн.</div>
                                        <div className="d-flex justify-content-center align-items-center">{refundPrice}</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Потрібно для відновлення, шт.</div>
                                        <div className="d-flex justify-content-center align-items-center">{restoreAmount}</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Ціна для відновлення, грн.</div>
                                        <div className="d-flex justify-content-center align-items-center">{restorePrice}</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Сума, грн.</div>
                                        <div className="d-flex justify-content-center align-items-center">{
                                            amount * rentalPrice + lostAmount * refundPrice + restoreAmount * restorePrice
                                        }</div>
                                    </div>
                                    {Object.keys(orderItemsStatuses).map((status, statusIndex) => {
                                        if (status != 'new') {
                                            const checked = getOrderItemStatusIndex(item.status) >= getOrderItemStatusIndex(status)
                                            return (
                                                <div className="order-items-mobile-item-row d-flex" key={statusIndex}>
                                                    <div className="d-flex justify-content-center align-items-center">{orderItemsStatuses[status]}</div>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <label htmlFor={`order-item-${item.id}-${status}`}>
                                                            <input
                                                                id={`order-item-${item.id}-${status}`}
                                                                style={{ display: 'none' }}
                                                                type="checkbox"
                                                                checked={checked}
                                                                onChange={(e) => setOrderItemStatus(e, id, status)}
                                                            />
                                                            <img src={checked ? checkboxActive : checkboxInactive} alt="" />
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Сума, грн.</div>
                                        <div className="d-flex justify-content-center align-items-center">{
                                            amount * rentalPrice + lostAmount * refundPrice + restoreAmount * restorePrice
                                        }</div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Знижка, %</div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <input className="w-100 h-100 order-discount text-black" type="text" name="discount_percent" value={discount || 0} ref={discountRef} onChange={(e) => handleDiscountChange(e)} />
                                        </div>
                                    </div>
                                    <div className="order-items-mobile-item-row d-flex">
                                        <div className="d-flex justify-content-center align-items-center">Всього, грн.</div>
                                        <div className="d-flex justify-content-center align-items-center">{totalCost}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div></>}
                    <div className="project-order-items-actions w-100 d-flex justify-content-end align-items-center">
                        <button className="btn-medium btn-black" onClick={handleAddOrderItemClick}>
                            <img className="btn-icon-plus" src={plus} alt="" />
                            <span>Додати поле вручну</span>
                        </button>
                        <button className="btn-medium btn-black" onClick={() => goToCatalog(`/catalog/owner/${order.owner.id}`)}>
                            <img className="btn-icon-plus" src={plus} alt="" />
                            <span>Додати з каталогу компанії</span>
                        </button>
                    </div>
                    <AddOrderItem
                        isOpen={addItemPopupOpen}
                        onConfirm={handleConfirmAddOrderItem}
                        onCancel={handleCancelAddOrderItem}
                    />
                </div>}
                {isOwn && <div className="product-save d-flex justify-content-center align-items-center">
                    <button className="btn-medium btn-black" onClick={saveOrder}>
                        <span>Зберегти замовлення</span>
                    </button>                   
                </div>}
            </div>
        </div>
    )
}

export default Order;
