import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';
import { getMyNotifications, readNotification } from '../../actions/notification';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { navItems } from '../../constants/header';
import { API_URL } from '../../constants/api';
import { getDatetimeFormat } from '../../utils/datetime'
import bellIcon from '../../assets/icons/bell.svg';
import userIcon from '../../assets/icons/user.svg';
import burgerIcon from '../../assets/icons/burger.svg';

const socket = io(API_URL, {
    auth: { token: localStorage.getItem('token') },
    withCredentials: true
});
  
const Actions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector(state => state.auth.user);
    const username = user ? user.profile.firstname : null;

    const myNotifications = useSelector(state => state.notification.myNotifications);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState(0);

    const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);
    const notificationsMenuRef = useRef();

    const [authMenuOpen, setAuthMenuOpen] = useState(false);
    const authMenuRef = useRef();

    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const navMenuRef = useRef();

    useEffect(() => {
        if (user) dispatch(getMyNotifications());
    }, [dispatch, user]);

    useEffect(() => {
        if (user) setNotifications(myNotifications);
        else setNotifications([]);
    }, [myNotifications, user]);

    useEffect(() => {
        socket.on('sendNotification', (notification) => {
            setNotifications((prevNotifications) => {
                const exists = prevNotifications.some((n) => n.id === notification.id);
                if (!exists) {
                    return [notification, ...prevNotifications];
                }
                return prevNotifications;
            });
        });

        return () => {
            socket.off('sendNotification');
        }
    }, []);

    useEffect(() => {
        setUnreadNotifications(
            notifications.filter(item => !item.read).length
        )
    }, [notifications]);

    const handleNotificationClick = ({ id, link }) => {
        dispatch(readNotification({ id }));
        if (link) navigate(link);
    }

    useEffect(() => {
        setNotificationsMenuOpen(false);
        setAuthMenuOpen(false);
        setNavMenuOpen(false);
    }, [location.pathname]);

    const handleClickOutsideMenu = (event) => {
        if (notificationsMenuRef.current && !notificationsMenuRef.current.contains(event.target)) {
            setNotificationsMenuOpen(false);
        }
        if (authMenuRef.current && !authMenuRef.current.contains(event.target)) {
            setAuthMenuOpen(false);
        }
        if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
            setNavMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMenu);
        }
    }, []);

    const handleNotificationsClick = () => {
        setNotificationsMenuOpen(!notificationsMenuOpen);
    }

    const handleAccountClick = () => {
        setAuthMenuOpen(!authMenuOpen);
    }

    const handleMenuClick = () => {
        setNavMenuOpen(!navMenuOpen);
    }

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else return `${API_URL}/${path}`;
    }

    const handleLogout = () => {
        setAuthMenuOpen(false);
        setNavMenuOpen(false);
        dispatch(logout());
        navigate('/');
    }

    const unAuthorizedMenu = [
        { title: 'Увійти', link: '/login' },
        { title: 'Зареєструватись', link: '/register' }
    ];

    const authorizedMenu = [
        { title: 'Мій профіль', link: '/profile' },
        { title: 'Налаштування акаунту', link: '/account' },
        { title: 'Статистика', link: '/stats' },
        { title: 'Склад', link: '/store' },
        { title: 'Підписки', link: '/subscriptions' },
        { title: 'Вийти', action: handleLogout }
    ];

    const menu = user ? authorizedMenu : unAuthorizedMenu;
    const avatar = user && user.avatars.length ? getImageUrl(user.avatars[0].path) : userIcon;

    return (
        <div className="header-actions d-flex justify-content-between align-items-center relative">
            <div className="header-actions-language"></div>
            <div
                className="header-actions-notifications relative"
                onClick={() => handleNotificationsClick()}
            >
                {unreadNotifications !== 0 && (
                    <div className="header-actions-notifications-marker absolute"></div>
                )}
                <img className="w-100" src={bellIcon} alt="" />
                {notificationsMenuOpen && (
                    <div
                        ref={notificationsMenuRef}
                        className="header-actions-notifications-menu d-flex flex-column align-items-center absolute"
                    >
                        {notifications.length !== 0 && notifications.map((item, index) => {
                            const { id, text, link, read, createdAt } = item
                            return (
                                <div
                                    className={`header-actions-notifications-menu-item ${read ? 'old' : ''} w-100 d-flex justify-content-between align-items-center`}
                                    key={index}
                                    onClick={() => handleNotificationClick({ id, link })}
                                >
                                    <div className="header-actions-notifications-menu-item-content">
                                        <div>{getDatetimeFormat(createdAt)}</div>
                                        <div>{text}</div>
                                    </div>
                                    {!read && <div className="header-actions-notifications-menu-item-marker"></div>}
                                </div>
                            )
                        })}
                        {notifications.length === 0 && (
                            <div className="header-actions-notifications-menu-item text-center">Немає нових сповіщень</div>
                        )}
                    </div>
                )}
            </div>
            <div
                className="header-actions-account d-flex flex-column justify-content-between align-items-center"
                onClick={() => handleAccountClick()}
            >
                <div  className="header-actions-account-avatar">
                    <img className="w-100" src={avatar} alt="" />
                </div>
                {username && <div className="header-actions-account-name">{username}</div>}                  
            </div>
            {authMenuOpen && <div ref={authMenuRef} className="header-actions-account-menu d-flex flex-column align-items-center absolute">
                {menu.map((item, index) => {
                    const { title, link, action } = item;
                    if (link) return (
                        <Link
                            to={link}
                            className="header-actions-account-menu-item text-center"
                            key={index}
                        >{title}</Link>
                    )
                    else if (action) return (
                        <a
                            onClick={action}
                            className="header-actions-account-menu-item text-center"
                            key={index}
                        >{title}</a>
                    )
                })}
            </div>}
            <div
                className="header-actions-menu"
                onClick={() => handleMenuClick()}
            >
                <img className="w-100" src={burgerIcon} alt="" />
            </div>
           {navMenuOpen && <div ref={navMenuRef} className="header-actions-menu-dropdown header-actions-account-menu d-flex flex-column align-items-center absolute">
                {navItems.map((item, index) => {
                    const { title, link } = item;
                    return (
                        <Link
                            to={link}
                            className="header-actions-account-menu-item text-center"
                            key={index}
                        >{title}</Link>
                    )
                })}                
            </div>}
        </div>
    )
}

export default Actions;
